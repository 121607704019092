import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import AddProxyLogin from './AddProxyLogin'
import SwitchProxyLogin from './SwitchProxyLogin'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserSecret, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import  secureLocalStorage  from  "react-secure-storage";

export default function ProxyLogin(props){
    
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const [popupType, setPopupType] = useState(props?.popupType ?? 1);

    const [adminData, setAdminData] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [proxyTeamData, setProxyTeamData] = useState([]);


    var dragElement = useRef(), dragElementCurrent;
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    function dragMouseDown(e) {
        dragElementCurrent = dragElement.current;
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }
    
    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        
        var top = (dragElementCurrent.offsetTop - pos2);
        var left = (dragElementCurrent.offsetLeft - pos1);

        if(top > 0 && top < (window.innerHeight - dragElementCurrent.offsetHeight)){
            dragElementCurrent.style.top = top + 'px';
        }

        if(left > 0 && left < (window.innerWidth - dragElementCurrent.offsetWidth)){
            dragElementCurrent.style.left = left + 'px';
        }
    }
    
    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }

    function toggleProxyBox() {
        setIsPopupOpen(oldState=>!oldState);
    }

    useEffect(()=>{
        if(secureLocalStorage.getItem('admin_data')){
            setAdminData( JSON.parse(secureLocalStorage.getItem('admin_data')) );
            setShowPopup(true);
            setProxyTeamData(JSON.parse(secureLocalStorage.getItem('proxy_client_data')));
        }
    }, []);

    return (
        <>
        {showPopup &&
            <ProxyLoginWrap>
                <div className='proxy-box-wrap' ref={dragElement} onMouseDown={dragMouseDown}>
                    <div className={'proxy-box-content' + (isPopupOpen ? '' : ' hidden')}>
                        { popupType == 1 ? (
                            <AddProxyLogin setIsAdmin={props.setIsAdmin} setLoading={props.setLoading}/>
                        ) : (
                            <SwitchProxyLogin proxyTeamData={proxyTeamData} />
                        )}
                    </div>
                    <div className={'proxy-box-closed' + (isPopupOpen ? ' hidden' : '')}>
                        <FontAwesomeIcon icon={faUserSecret} />
                    </div>
                    <div className='proxy-btns' onClick={toggleProxyBox}>
                        <FontAwesomeIcon icon={isPopupOpen ? faMinusCircle : faPlusCircle} />
                    </div>
                </div>
            </ProxyLoginWrap>
        }
        </>
    )
}

const ProxyLoginWrap = styled.div`
    & .proxy-box-wrap {    
        position: fixed;
        left: 50%;
        top: 45%;
        z-index: 999999;
        background-color: #f7941c;
        color: white;
        padding: 10px;
        cursor: move;
    }
    & .proxy-box-wrap svg{
        width: 16px;
    }
    & .proxy-box-content {
        width: 430px;
    }
    & .proxy-box-closed {
        min-width: 80px;
        text-align: center;
    }
    & .proxy-box-closed svg {
        font-size: 35px;
    }
    & .proxy-btns {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
    }
    & .hidden {
        display: none;
    }
`