import React from 'react'
import {Navbar, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import {FaAngleDown} from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/prodocs-logo.png';
import {Link} from 'gatsby';
import './removeCaret.css'

export default function LoginMenu() {
    const navDropdownTitle = (<div className="d-flex align-items-center justify-content-center"><p className="m-0">DropDown</p><FaAngleDown style={{margin:'0 3px'}}  size={15}>  </FaAngleDown></div>);
    return (
        <div >
            <Navbar collapseOnSelect expand="lg" className="text-black" style={{background:'#FFFFFF'}}>
                <Navbar.Brand href="/">
                <img src={Logo} style={{width:'120px', height:'auto', marginTop:'-5px'}} alt=""/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
