import React ,{useState, useEffect} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {Link, navigate} from 'gatsby';
import styled from 'styled-components';
import config from '../../aws-exports';
import {adminLogin} from '../../graphql/queries'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { useSelector, useDispatch } from "react-redux";
import Loader from '../../assets/loading.gif';
import ProxyLogin from '../ProxyLogin/ProxyLogin'
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);

const AdminLogin = () => {
    const {consoleLog} = require('../commonFunctions.js');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [genericError, setgenericError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminData, setAdminData] = useState({});

    const dispatch = useDispatch();
    async function verifyAdminLogin (){
        setLoading(true);
        const result = await API.graphql({
            query: adminLogin,
            variables: {
                user_name: email.trim(),
                password: password.trim()
            }
        })
        
        const adminRes = JSON.parse(result.data.adminLogin);
        consoleLog('login result: ', result);
        consoleLog('login adminRes: ', adminRes);

        if(adminRes.statusCode != 200){
            setgenericError("Login failed. Please try again later.");
            setLoading(false);
            return;
        }

        setAdminData(JSON.parse(adminRes.body));
        consoleLog('login adminData: ', adminData);
        
        // For Super Admin
        secureLocalStorage.setItem('admin_data', JSON.stringify(JSON.parse(adminRes.body)));

        setIsAdmin(true);
        setLoading(false);
        
        if(secureLocalStorage.getItem('client_data')){
            secureLocalStorage.removeItem('client_data');
            secureLocalStorage.removeItem('user_token');
            secureLocalStorage.removeItem('es_down_docs');
        }
    }
    const handleEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value)
    }
    const handlePasswordChange = (e) => {
        setPasswordError('');
        setPassword(e.target.value)
    }
    const submitValue = () => {
        if(email == ''){
            setEmailError('Email Required')
        }
        if(password == ''){
            setPasswordError('Password Required')
        }
        if(email !== '' && password !== ''){
            verifyAdminLogin();
        }
    }

    useEffect(()=>{
        if(secureLocalStorage.getItem('admin_data')){
            setIsAdmin(true);
            setAdminData(JSON.parse(secureLocalStorage.getItem('admin_data')));
            consoleLog('adminData', secureLocalStorage.getItem('admin_data'));
        }
        else {
            setIsAdmin(false);
        }
    }, []);

    return (
        <>
            { loading ?
                <LoadingContainer>
                    <img className="loader" src={Loader}/>
                </LoadingContainer>

            :
            <> { isAdmin ?
                <>
                    <ProxyLogin isProxyEnabled={false} setIsAdmin={setIsAdmin} setLoading={setLoading}/>
                    <AdminPage>
                        <h2>Welcome {adminData?.first_name + ' ' + adminData?.last_name}</h2>
                        <p> Please use the proxy login box to login as any user using User Id/Email </p>
                    </AdminPage>
                </>
                :
                <FormLogin>
                    <MDBContainer className="w-100">

                        <MDBRow className="w-100 align-items-center justify-content-center m-0">
                            <MDBCol md="6">

                                <form className="w-100">
                                    <p className="h4 text-center mb-4">Admin Login</p>
                                    <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                                        Your email
                                    </label>
                                    <input type="email" id="defaultFormLoginEmailEx" className="form-control" onChange={handleEmailChange} />
                                    {emailError &&
                                        <div style={{color:'red'}}>{emailError}</div>
                                    }
                                    <br />
                                    <label htmlFor="defaultFormLoginPasswordEx" className="grey-text">
                                        Your password
                                    </label>
                                    <input type="password" id="defaultFormLoginPasswordEx" className="form-control" onChange={handlePasswordChange} />
                                    {passwordError &&
                                        <div style={{color:'red'}}>{passwordError}</div>
                                    }

                                    {genericError && <div style={{color:'red'}} >{genericError}</div> }
                                    <div className="text-center mt-4">
                                        <Btn onClick={submitValue}>
                                            <div className="w-100" style={{ cursor: "pointer" }}>
                                                <Link className="w-100" style={{textDecoration:'none', color:'black'}} >
                                                    Login
                                                </Link>
                                            </div>
                                        </Btn>
                                    </div>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </FormLogin>
            }
            </>
            }
        </>
    )
};

export default AdminLogin;

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
    }
`

const FormLogin = styled.div`
    width:100%;
    height:85vh;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
`
const Btn = styled.div`
    width:100%;
    height:40px;
    border-radius:5px;
    text-transform:uppercase;
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fbc846;
`
const AdminPage = styled.div`
    width: 100%;
    height: 85vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
