import React from 'react'
import Footer from '../Components/Footer/Footer'
import AdminLogin from '../Components/Login/AdminLogin'
import LoginMenu from '../Components/Navbar/LoginNavbar'

export default function login() {
    return (
        <div>
            <LoginMenu/>
            <AdminLogin/>
            <Footer/>
        </div>
    )
}

