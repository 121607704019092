import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheck, faStarHalfAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { createOnRedis } from "../../graphql/queries";
import {Link, navigate} from 'gatsby';
import  secureLocalStorage  from  "react-secure-storage";

export default function SwitchProxyLogin(props){
    const {consoleLog} = require('../commonFunctions.js');
    const [client, setClient] = useState({});
    const [clientTeam, setClientTeam] = useState({});

    useEffect(()=>{
        if(secureLocalStorage.getItem('client_data')){
            setClient( JSON.parse(secureLocalStorage.getItem('client_data')) );
        }

        if(secureLocalStorage.getItem('client_team')){
            setClientTeam( JSON.parse(secureLocalStorage.getItem('client_team')) );
        }
        consoleLog('x>>>', props.proxyTeamData);
    }, [])

    function logout(){
        consoleLog("logout", "()");
        if (typeof window !== 'undefined') {
                secureLocalStorage.removeItem('client_data');
                secureLocalStorage.removeItem('user_token');
                secureLocalStorage.removeItem('es_down_docs');
                navigate('/prodocs-admin-login');
        }
    }

    async function changeProxyUser(id){
        consoleLog('changeProxyUser', id);
        consoleLog('changeProxyUser', secureLocalStorage.getItem('client_data'));
        consoleLog('changeProxyUser', secureLocalStorage.getItem('user_token'));
        let user = props.proxyTeamData.filter((c) => c.client_id == id);
        consoleLog('changeProxyUser', user[0]);

        const create_on_redis = await API.graphql({
            query: createOnRedis,
            variables: {
              id: user[0].client_id,
              index: 'prodocs_user_auth_data',
              data: JSON.stringify(user[0])
            }
        });
        consoleLog('changeProxyUser create_on_redis:', create_on_redis);

        secureLocalStorage.setItem('client_data', JSON.stringify(user[0]));
        secureLocalStorage.setItem('user_token', user[0].token);
        navigate('/loading');
    }

    return (
        <SwitchLoginWrap>
            <div className="proxy_users">
                <div className='proxy_active_user'>
                    {client.team_role == 'Leader' &&
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                    }
                    {client.team_role == 'Admin' || client.team_role == '' && client.client_id == client.leader_id &&
                        <FontAwesomeIcon icon={faStar} />
                    }
                    {client.team_role == 'Member' &&
                        <FontAwesomeIcon icon={faUser} />
                    }
                    &nbsp;&nbsp;{client.email} ({client.client_id})
                    { client.zoho_id && client.zoho_id != 0 &&
                        <a href={'https://crm.zoho.com/crm/EntityInfo.do?module=Accounts&id='+client.zoho_id} target="_blank"> ZOHO</a>
                    }
                    <span className='pull-right' style={{marginRight:"5%"}}>${client.account_balance}</span>
                    <div className='proxy_active_user_props'>
                        <div>Team ID: {client.team_id}</div>
                        {client.team_role == 'Leader' &&
                            <div> <FontAwesomeIcon icon={faCheck} /> Team Leader </div>
                        }
                        {client.team_role == 'Admin' || client.team_role == '' && client.client_id == client.leader_id &&
                            <div> <FontAwesomeIcon icon={faCheck} /> Team Admin </div>
                        }
                        {client.team_role == 'Member' &&
                            <div> <FontAwesomeIcon icon={faCheck} /> Team Member </div>
                        }
                        { client.sales_rep_name &&
                            <div>Sales Rep: {client.sales_rep_name}</div>
                        }
                    </div>
                    <div className='proxy_active_user_props'>
                        {client.autopay == 1 &&
                            <div><FontAwesomeIcon icon={faCheck} /> Easy Pay</div>
                        }
                        {client.is_partner == 1 &&
                            <div><FontAwesomeIcon icon={faCheck} /> Partner</div>
                        }
                        {client.is_vendor == 1 &&
                            <div><FontAwesomeIcon icon={faCheck} /> Vendor</div>
                        }
                    </div>
                </div>
            </div>
            { props.proxyTeamData.length > 1 &&
                <div className="proxy_users">
                    Team Members
                    { props.proxyTeamData.filter((c) => c.client_id != client.client_id).map((td, i) => (
                        <>
                            <div>
                                {td.team_role == 'Leader' &&
                                    <FontAwesomeIcon icon={faStarHalfAlt} />
                                }
                                {td.team_role == 'Admin' || td.team_role == '' && td.client_id == td.leader_id &&
                                    <FontAwesomeIcon icon={faStar} />
                                }
                                {td.team_role == 'Member' &&
                                    <FontAwesomeIcon icon={faUser} />
                                }
                                <span>&nbsp;&nbsp;{td.email}</span><a className='pull-right' onClick={()=>changeProxyUser(td.client_id)}>Change</a>
                            </div>
                        </>
                    ))}
                </div>
            }
            <div className='proxy_links'>
                {/* <a>Admin</a> */}
                <a onClick={logout}>Logout as User</a>
            </div>
        </SwitchLoginWrap>
    )
}

const SwitchLoginWrap = styled.div`
    & .proxy_users {
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
    }
    & .proxy_active_user a {
        color: white;
    }
    & .proxy_active_user_props {
        display: flex;
    }
    & .proxy_active_user_props div {
        padding-top: 10px;
        padding-right: 10px;
    }
    & .proxy_links {
        display: flex;
        justify-content: space-between;
    }
    & .proxy_links a {
        font-weight: bold;
    }
`