import React, {useState, useRef} from 'react';
import styled from "styled-components";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getUserDataForProxyLogin, getEsDownDocs, createOnRedis } from "../../graphql/queries";
import {Link, navigate} from 'gatsby';
import  secureLocalStorage  from  "react-secure-storage";

export default function AddProxyLogin(props){
    const {consoleLog} = require('../commonFunctions.js');
    const [proxyId, setProxyId] = useState('');
    const proxyIdField = useRef();

    function preventDrag(e){
        e.stopPropagation();
    }

    function handleInput(e){
        setProxyId(e.target.value);
    }

    function handleInputKeyUp(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleProxyLogin();
        }
    }

    async function handleProxyLogin() {
        if(proxyId == ""){
            proxyIdField.current.reportValidity();
            return;
        }

        props.setLoading(true);

        let id = 0;
        let email = proxyId;

        if(isNumeric(proxyId)){
            consoleLog("handleProxyLogin proxyId is INT : ", proxyId);
            id = proxyId;
            email = 'null';
        }
        else{
            consoleLog("handleProxyLogin proxyId is STRING : ", proxyId);
        }

        consoleLog("handleProxyLogin id>", id);
        consoleLog("handleProxyLogin email>", email);
        try{
            const result = await API.graphql({
                query: getUserDataForProxyLogin,
                variables: {
                    id: id,
                    email: email
                }
            });
            let userData = JSON.parse(result.data.getUserDataForProxyLogin);
            userData = JSON.parse(userData);
            
            for(let i=0; i<userData.length; i++){
                if(userData[i].client_id == id || userData[i].email == email){
                    userData[i].isLoggedIn = 1;
                }else{
                    userData[i].isLoggedIn = 0;
                }
                consoleLog('handleProxyLogin for>', userData[i]);
            }
            
            consoleLog("handleProxyLogin userData", userData);
            secureLocalStorage.setItem('proxy_client_data', JSON.stringify(userData));

            let userDatax = {};
            if(id == 0){
                userDatax = userData.filter((t) => t.email == email);
                consoleLog("handleProxyLogin userDatax if>", userDatax);
            }else{
                userDatax = userData.filter((t) => t.client_id == id);
                consoleLog("handleProxyLogin userDatax else>", userDatax);
            }

            const esd = await API.graphql({
                query: getEsDownDocs,
                  variables: {
                    team_id: userDatax[0].team_id
                }
            });
            console.log('handleProxyLogin es_down_docs>',esd.data.getEsDownDocs);
            secureLocalStorage.setItem('es_down_docs', esd.data.getEsDownDocs);

            const create_on_redis = await API.graphql({
                query: createOnRedis,
                variables: {
                  id: userDatax[0].client_id,
                  index: 'prodocs_user_auth_data',
                  data: JSON.stringify(userDatax[0])
                }
            });
            consoleLog('handleProxyLogin create_on_redis:', create_on_redis);

            secureLocalStorage.setItem('client_data', JSON.stringify(userDatax[0]));
            secureLocalStorage.setItem('user_token', userDatax[0].token);
            navigate('/document');
        }catch(err){
            console.error(err);
            props.setLoading(false);
            alert('Please enter a valid user id / email.');
        }
    }

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    function logout(){
        consoleLog("logout", "()");
        if (typeof window !== 'undefined') {
            secureLocalStorage.clear();
            props.setIsAdmin(false);
        }
    }

    return (
        <AddLoginWrap>
            <div className="proxy_as_wrap">
                <label for="proxy_as">Proxy In As: </label> <br />
                <input
                    type="text"
                    id="proxy_as"
                    name="proxy_as"
                    value={proxyId}
                    ref={proxyIdField}
                    onMouseDown={preventDrag}
                    onInput={handleInput}
                    onKeyUp={handleInputKeyUp}
                    required="required"
                />
            </div>
            <div className='proxy_links'>
                <a onClick={handleProxyLogin}>Proxy In</a>
                <a onClick={logout}>Logout as Admin </a>
            </div>
        </AddLoginWrap>
    )
}

const AddLoginWrap = styled.div`
    & .proxy_as_wrap {
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        margin-bottom: 10px;
    }
    & .proxy_as_wrap input {
        padding: 5px;
        width: 100%;
        border: none;
        color: black;
    }
    & .proxy_links {
        display: flex;
        justify-content: space-between;
    }
    & .proxy_links a {
        font-weight: bold;
    }
`